// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as CoursesReport__Overview from "./CoursesReport__Overview.bs.js";
import * as CoursesReport__StudentOverview from "../types/CoursesReport__StudentOverview.bs.js";
import * as CoursesReport__SubmissionsList from "./CoursesReport__SubmissionsList.bs.js";
import * as CoursesReport__EvaluationCriterion from "../types/CoursesReport__EvaluationCriterion.bs.js";
import * as CoursesReport__MilestoneCompletionStatus from "../types/CoursesReport__MilestoneCompletionStatus.bs.js";

import "./CoursesReport.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesReport";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function buttonClasses(selected) {
  return "cursor-pointer flex flex-1 justify-center md:flex-auto rounded-md p-1.5 md:border-b-3 md:rounded-b-none  md:px-4 md:hover:bg-gray-50 md:py-2 text-sm font-semibold text-gray-800 hover:text-primary-600 hover:bg-gray-50 focus:outline-none focus:ring-inset focus:ring-2 focus:bg-gray-50 focus:ring-focusColor-500 md:focus:border-b-none md:focus:rounded-t-md " + (
          selected ? "bg-white shadow md:shadow-none rounded-md md:rounded-none md:bg-transparent md:border-b-3 hover:bg-white md:hover:bg-transparent text-primary-500 md:border-primary-500" : "md:border-transparent"
        );
}

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* SelectOverviewTab */0) {
      return {
              selectedTab: "Overview",
              overviewData: state.overviewData,
              submissionsData: state.submissionsData,
              submissionsFilter: state.submissionsFilter,
              sortDirection: state.sortDirection
            };
    } else {
      return {
              selectedTab: "Submissions",
              overviewData: state.overviewData,
              submissionsData: state.submissionsData,
              submissionsFilter: state.submissionsFilter,
              sortDirection: state.sortDirection
            };
    }
  }
  switch (action.TAG | 0) {
    case /* SaveOverviewData */0 :
        return {
                selectedTab: state.selectedTab,
                overviewData: action._0,
                submissionsData: state.submissionsData,
                submissionsFilter: state.submissionsFilter,
                sortDirection: state.sortDirection
              };
    case /* SaveSubmissions */1 :
        return {
                selectedTab: state.selectedTab,
                overviewData: state.overviewData,
                submissionsData: action._0,
                submissionsFilter: state.submissionsFilter,
                sortDirection: state.sortDirection
              };
    case /* UpdateStatusFilter */2 :
        return {
                selectedTab: state.selectedTab,
                overviewData: state.overviewData,
                submissionsData: state.submissionsData,
                submissionsFilter: {
                  selectedStatus: action._0
                },
                sortDirection: state.sortDirection
              };
    case /* UpdateSortDirection */3 :
        return {
                selectedTab: state.selectedTab,
                overviewData: state.overviewData,
                submissionsData: state.submissionsData,
                submissionsFilter: state.submissionsFilter,
                sortDirection: action._0
              };
    
  }
}

var Raw = {};

var query = "query StudentReportOverviewQuery($studentId: ID!)  {\nstudentDetails(studentId: $studentId)  {\nevaluationCriteria  {\nid  \nname  \nmaxGrade  \n}\n\nstudent  {\ncohort  {\nname  \n}\n\n}\n\ntotalPageReads  \ntotalTargets  \nassignmentsCompleted  \nassignmentsPendingReview  \ntotalAssignments  \nquizScores  \naverageGrades  {\nevaluationCriterionId  \naverageGrade  \n}\n\nmilestonesCompletionStatus  {\nid  \ntitle  \ncompleted  \nmilestoneNumber  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.studentDetails;
  var value$2 = value$1.evaluationCriteria;
  var value$3 = value$1.student;
  var value$4 = value$3.cohort;
  var value$5 = value$1.quizScores;
  var value$6 = value$1.averageGrades;
  var value$7 = value$1.milestonesCompletionStatus;
  return {
          studentDetails: {
            evaluationCriteria: Js_array.map((function (value) {
                    return {
                            id: value.id,
                            name: value.name,
                            maxGrade: value.maxGrade
                          };
                  }), value$2),
            student: {
              cohort: {
                name: value$4.name
              }
            },
            totalPageReads: value$1.totalPageReads,
            totalTargets: value$1.totalTargets,
            assignmentsCompleted: value$1.assignmentsCompleted,
            assignmentsPendingReview: value$1.assignmentsPendingReview,
            totalAssignments: value$1.totalAssignments,
            quizScores: Js_array.map((function (value) {
                    return value;
                  }), value$5),
            averageGrades: Js_array.map((function (value) {
                    return {
                            evaluationCriterionId: value.evaluationCriterionId,
                            averageGrade: value.averageGrade
                          };
                  }), value$6),
            milestonesCompletionStatus: Js_array.map((function (value) {
                    return {
                            id: value.id,
                            title: value.title,
                            completed: value.completed,
                            milestoneNumber: value.milestoneNumber
                          };
                  }), value$7)
          }
        };
}

function serialize(value) {
  var value$1 = value.studentDetails;
  var value$2 = value$1.milestonesCompletionStatus;
  var milestonesCompletionStatus = Js_array.map((function (value) {
          var value$1 = value.milestoneNumber;
          var value$2 = value.completed;
          var value$3 = value.title;
          var value$4 = value.id;
          return {
                  id: value$4,
                  title: value$3,
                  completed: value$2,
                  milestoneNumber: value$1
                };
        }), value$2);
  var value$3 = value$1.averageGrades;
  var averageGrades = Js_array.map((function (value) {
          var value$1 = value.averageGrade;
          var value$2 = value.evaluationCriterionId;
          return {
                  evaluationCriterionId: value$2,
                  averageGrade: value$1
                };
        }), value$3);
  var value$4 = value$1.quizScores;
  var quizScores = Js_array.map((function (value) {
          return value;
        }), value$4);
  var value$5 = value$1.totalAssignments;
  var value$6 = value$1.assignmentsPendingReview;
  var value$7 = value$1.assignmentsCompleted;
  var value$8 = value$1.totalTargets;
  var value$9 = value$1.totalPageReads;
  var value$10 = value$1.student;
  var value$11 = value$10.cohort;
  var value$12 = value$11.name;
  var cohort = {
    name: value$12
  };
  var student = {
    cohort: cohort
  };
  var value$13 = value$1.evaluationCriteria;
  var evaluationCriteria = Js_array.map((function (value) {
          var value$1 = value.maxGrade;
          var value$2 = value.name;
          var value$3 = value.id;
          return {
                  id: value$3,
                  name: value$2,
                  maxGrade: value$1
                };
        }), value$13);
  var studentDetails = {
    evaluationCriteria: evaluationCriteria,
    student: student,
    totalPageReads: value$9,
    totalTargets: value$8,
    assignmentsCompleted: value$7,
    assignmentsPendingReview: value$6,
    totalAssignments: value$5,
    quizScores: quizScores,
    averageGrades: averageGrades,
    milestonesCompletionStatus: milestonesCompletionStatus
  };
  return {
          studentDetails: studentDetails
        };
}

function serializeVariables(inp) {
  return {
          studentId: inp.studentId
        };
}

function makeVariables(studentId, param) {
  return {
          studentId: studentId
        };
}

var StudentReportOverviewQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var StudentReportOverviewQuery_Graphql_error = include.Graphql_error;

var StudentReportOverviewQuery_decodeNotification = include.decodeNotification;

var StudentReportOverviewQuery_decodeObject = include.decodeObject;

var StudentReportOverviewQuery_decodeNotifications = include.decodeNotifications;

var StudentReportOverviewQuery_decodeErrors = include.decodeErrors;

var StudentReportOverviewQuery_flashNotifications = include.flashNotifications;

var StudentReportOverviewQuery_sendQuery = include.sendQuery;

var StudentReportOverviewQuery_query = include.query;

var StudentReportOverviewQuery_make = include.make;

var StudentReportOverviewQuery = {
  StudentReportOverviewQuery_inner: StudentReportOverviewQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: StudentReportOverviewQuery_Graphql_error,
  decodeNotification: StudentReportOverviewQuery_decodeNotification,
  decodeObject: StudentReportOverviewQuery_decodeObject,
  decodeNotifications: StudentReportOverviewQuery_decodeNotifications,
  decodeErrors: StudentReportOverviewQuery_decodeErrors,
  flashNotifications: StudentReportOverviewQuery_flashNotifications,
  sendQuery: StudentReportOverviewQuery_sendQuery,
  query: StudentReportOverviewQuery_query,
  $$fetch: $$fetch,
  make: StudentReportOverviewQuery_make
};

function getOverviewData(studentId, send, param) {
  Js_promise.$$catch((function (param) {
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (response) {
              var evaluationCriteria = response.studentDetails.evaluationCriteria.map(function (evaluationCriterion) {
                    return CoursesReport__EvaluationCriterion.make(evaluationCriterion.id, evaluationCriterion.name, evaluationCriterion.maxGrade);
                  });
              var averageGrades = response.studentDetails.averageGrades.map(function (gradeData) {
                    return CoursesReport__StudentOverview.makeAverageGrade(gradeData.evaluationCriterionId, gradeData.averageGrade);
                  });
              var milestonesCompletionStatus = response.studentDetails.milestonesCompletionStatus.map(function (milestone) {
                    return CoursesReport__MilestoneCompletionStatus.make(milestone.id, milestone.title, milestone.completed, milestone.milestoneNumber);
                  });
              var overviewData = CoursesReport__StudentOverview.make(studentId, response.studentDetails.student.cohort.name, evaluationCriteria, response.studentDetails.totalPageReads, response.studentDetails.totalTargets, response.studentDetails.assignmentsCompleted, response.studentDetails.totalAssignments, response.studentDetails.quizScores, averageGrades, response.studentDetails.assignmentsPendingReview, milestonesCompletionStatus);
              Curry._1(send, {
                    TAG: /* SaveOverviewData */0,
                    _0: /* Loaded */{
                      _0: overviewData
                    }
                  });
              return Promise.resolve(undefined);
            }), Curry._3($$fetch, undefined, undefined, {
                studentId: studentId
              })));
}

function updateSubmissions(send, submissions) {
  return Curry._1(send, {
              TAG: /* SaveSubmissions */1,
              _0: submissions
            });
}

function CoursesReport__Root(Props) {
  var studentId = Props.studentId;
  var coaches = Props.coaches;
  var teamStudentIds = Props.teamStudentIds;
  var match = React.useReducer(reducer, {
        selectedTab: "Overview",
        overviewData: /* Unloaded */0,
        submissionsData: /* Unloaded */0,
        submissionsFilter: {
          selectedStatus: undefined
        },
        sortDirection: "Descending"
      });
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          return getOverviewData(studentId, send, undefined);
        }), [studentId]);
  var match$1 = state.selectedTab;
  return React.createElement("div", {
              "aria-label": "Report",
              className: "md:pt-18 pb-20 md:pb-5 px-4 bg-gray-50 md:h-screen overflow-y-auto",
              role: "main"
            }, React.createElement("div", {
                  className: "bg-gray-50 sticky top-0 z-10"
                }, React.createElement("div", {
                      className: "max-w-3xl mx-auto"
                    }, React.createElement("div", {
                          className: "flex pt-3 md:border-b border-gray-300"
                        }, React.createElement("div", {
                              "aria-label": "Status tabs",
                              className: "flex flex-1 md:flex-none p-1 md:p-0 space-x-1 md:space-x-0 text-center rounded-lg justify-between md:justify-start bg-gray-300 md:bg-transparent",
                              role: "tablist"
                            }, React.createElement("button", {
                                  "aria-selected": state.selectedTab === "Overview",
                                  className: buttonClasses(state.selectedTab === "Overview"),
                                  role: "tab",
                                  onClick: (function (param) {
                                      Curry._1(send, /* SelectOverviewTab */0);
                                    })
                                }, tr(undefined, undefined, "button_overview_text")), React.createElement("button", {
                                  "aria-selected": state.selectedTab === "Submissions",
                                  className: buttonClasses(state.selectedTab === "Submissions"),
                                  role: "tab",
                                  onClick: (function (param) {
                                      Curry._1(send, /* SelectSubmissionsTab */1);
                                    })
                                }, tr(undefined, undefined, "button_submissions_text")))))), React.createElement("div", {
                  className: ""
                }, match$1 === "Overview" ? React.createElement(CoursesReport__Overview.make, {
                        overviewData: state.overviewData,
                        coaches: coaches
                      }) : React.createElement(CoursesReport__SubmissionsList.make, {
                        studentId: studentId,
                        submissions: state.submissionsData,
                        updateSubmissionsCB: (function (param) {
                            return Curry._1(send, {
                                        TAG: /* SaveSubmissions */1,
                                        _0: param
                                      });
                          }),
                        teamStudentIds: teamStudentIds,
                        selectedStatus: state.submissionsFilter.selectedStatus,
                        sortDirection: state.sortDirection,
                        updateSelectedStatusCB: (function (status) {
                            Curry._1(send, {
                                  TAG: /* UpdateStatusFilter */2,
                                  _0: status
                                });
                          }),
                        updateSortDirectionCB: (function (direction) {
                            Curry._1(send, {
                                  TAG: /* UpdateSortDirection */3,
                                  _0: direction
                                });
                          })
                      })));
}

var make = CoursesReport__Root;

export {
  str ,
  tr ,
  buttonClasses ,
  reducer ,
  StudentReportOverviewQuery ,
  getOverviewData ,
  updateSubmissions ,
  make ,
}
/*  Not a pure module */
