// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as EmojiPicker from "../../../shared/components/EmojiPicker.bs.js";
import * as Js_promise2 from "../../../../../node_modules/rescript/lib/es6/js_promise2.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as Belt_MapString from "../../../../../node_modules/rescript/lib/es6/belt_MapString.js";
import * as CoursesCurriculum__Reaction from "../types/CoursesCurriculum__Reaction.bs.js";
import * as CoursesCurriculum__CurrentUser from "../types/CoursesCurriculum__CurrentUser.bs.js";
import * as CoursesCurriculum__ReactionButton from "./CoursesCurriculum__ReactionButton.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__Reactions";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation CreateReactionMutation($reactionValue: String!, $reactionableId: String!, $reactionableType: String!)  {\ncreateReaction(reactionValue: $reactionValue, reactionableId: $reactionableId, reactionableType: $reactionableType)  {\nreaction  {\nid  \nreactionValue  \nreactionableId  \nreactionableType  \nuserName  \nuserId  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.createReaction;
  var value$2 = value$1.reaction;
  var value$3 = value$2.reactionableId;
  return {
          createReaction: {
            reaction: {
              id: value$2.id,
              reactionValue: value$2.reactionValue,
              reactionableId: !(value$3 == null) ? value$3 : undefined,
              reactionableType: value$2.reactionableType,
              userName: value$2.userName,
              userId: value$2.userId
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.createReaction;
  var value$2 = value$1.reaction;
  var value$3 = value$2.userId;
  var value$4 = value$2.userName;
  var value$5 = value$2.reactionableType;
  var value$6 = value$2.reactionableId;
  var reactionableId = value$6 !== undefined ? value$6 : null;
  var value$7 = value$2.reactionValue;
  var value$8 = value$2.id;
  var reaction = {
    id: value$8,
    reactionValue: value$7,
    reactionableId: reactionableId,
    reactionableType: value$5,
    userName: value$4,
    userId: value$3
  };
  var createReaction = {
    reaction: reaction
  };
  return {
          createReaction: createReaction
        };
}

function serializeVariables(inp) {
  return {
          reactionValue: inp.reactionValue,
          reactionableId: inp.reactionableId,
          reactionableType: inp.reactionableType
        };
}

function makeVariables(reactionValue, reactionableId, reactionableType, param) {
  return {
          reactionValue: reactionValue,
          reactionableId: reactionableId,
          reactionableType: reactionableType
        };
}

var CreateReactionMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CreateReactionMutation_Graphql_error = include.Graphql_error;

var CreateReactionMutation_decodeNotification = include.decodeNotification;

var CreateReactionMutation_decodeObject = include.decodeObject;

var CreateReactionMutation_decodeNotifications = include.decodeNotifications;

var CreateReactionMutation_decodeErrors = include.decodeErrors;

var CreateReactionMutation_flashNotifications = include.flashNotifications;

var CreateReactionMutation_sendQuery = include.sendQuery;

var CreateReactionMutation_query = include.query;

var CreateReactionMutation_fetch = include.$$fetch;

var CreateReactionMutation = {
  CreateReactionMutation_inner: CreateReactionMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateReactionMutation_Graphql_error,
  decodeNotification: CreateReactionMutation_decodeNotification,
  decodeObject: CreateReactionMutation_decodeObject,
  decodeNotifications: CreateReactionMutation_decodeNotifications,
  decodeErrors: CreateReactionMutation_decodeErrors,
  flashNotifications: CreateReactionMutation_flashNotifications,
  sendQuery: CreateReactionMutation_sendQuery,
  query: CreateReactionMutation_query,
  $$fetch: CreateReactionMutation_fetch,
  make: make
};

var Raw$1 = {};

var query$1 = "mutation RemoveReactionMutation($reactionId: String!)  {\nremoveReaction(reactionId: $reactionId)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.removeReaction;
  return {
          removeReaction: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.removeReaction;
  var value$2 = value$1.success;
  var removeReaction = {
    success: value$2
  };
  return {
          removeReaction: removeReaction
        };
}

function serializeVariables$1(inp) {
  return {
          reactionId: inp.reactionId
        };
}

function makeVariables$1(reactionId, param) {
  return {
          reactionId: reactionId
        };
}

var RemoveReactionMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include$1.$$fetch;

var RemoveReactionMutation_Graphql_error = include$1.Graphql_error;

var RemoveReactionMutation_decodeNotification = include$1.decodeNotification;

var RemoveReactionMutation_decodeObject = include$1.decodeObject;

var RemoveReactionMutation_decodeNotifications = include$1.decodeNotifications;

var RemoveReactionMutation_decodeErrors = include$1.decodeErrors;

var RemoveReactionMutation_flashNotifications = include$1.flashNotifications;

var RemoveReactionMutation_sendQuery = include$1.sendQuery;

var RemoveReactionMutation_query = include$1.query;

var RemoveReactionMutation_make = include$1.make;

var RemoveReactionMutation = {
  RemoveReactionMutation_inner: RemoveReactionMutation_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: RemoveReactionMutation_Graphql_error,
  decodeNotification: RemoveReactionMutation_decodeNotification,
  decodeObject: RemoveReactionMutation_decodeObject,
  decodeNotifications: RemoveReactionMutation_decodeNotifications,
  decodeErrors: RemoveReactionMutation_decodeErrors,
  flashNotifications: RemoveReactionMutation_flashNotifications,
  sendQuery: RemoveReactionMutation_sendQuery,
  query: RemoveReactionMutation_query,
  $$fetch: $$fetch,
  make: RemoveReactionMutation_make
};

function groupByReaction(reactions) {
  return Belt_Array.reduce(reactions, undefined, (function (accumulator, currentItem) {
                var reactionValue = CoursesCurriculum__Reaction.reactionValue(currentItem);
                var userName = CoursesCurriculum__Reaction.userName(currentItem);
                var userId = CoursesCurriculum__Reaction.userId(currentItem);
                var reactionDetails = Belt_MapString.get(accumulator, reactionValue);
                if (reactionDetails === undefined) {
                  return Belt_MapString.set(accumulator, reactionValue, {
                              userNames: [userName],
                              userIds: [userId],
                              count: 1
                            });
                }
                var reactionDetails$1 = Caml_option.valFromOption(reactionDetails);
                var newCount = reactionDetails$1.count + 1 | 0;
                return Belt_MapString.set(accumulator, reactionValue, {
                            userNames: [userName].concat(reactionDetails$1.userNames),
                            userIds: [userId].concat(reactionDetails$1.userIds),
                            count: newCount
                          });
              }));
}

function CoursesCurriculum__Reactions(Props) {
  var currentUser = Props.currentUser;
  var reactionableType = Props.reactionableType;
  var reactionableId = Props.reactionableId;
  var reactions = Props.reactions;
  var match = React.useState(function () {
        return reactions;
      });
  var setReactions = match[1];
  var reactions$1 = match[0];
  var handleCreateReaction = function (reactionValue) {
    Js_promise.then_((function (response) {
            var reaction = response.createReaction.reaction;
            if (reaction !== undefined) {
              var existingReaction = reactions$1.find(function (existingReaction) {
                    return CoursesCurriculum__Reaction.id(existingReaction) === CoursesCurriculum__Reaction.id(reaction);
                  });
              if (Belt_Option.isNone(existingReaction === undefined ? undefined : Caml_option.some(existingReaction))) {
                Curry._1(setReactions, (function (reactions) {
                        return [reaction].concat(reactions);
                      }));
              }
              
            }
            return Promise.resolve(undefined);
          }), Curry._3(make, undefined, undefined, {
              reactionValue: reactionValue,
              reactionableId: reactionableId,
              reactionableType: reactionableType
            }));
  };
  var removeReactionCB = function (reactionValue, $$event) {
    $$event.preventDefault();
    var reactionId = Belt_Array.reduce(reactions$1, undefined, (function (acc, reaction) {
            if (acc !== undefined) {
              return acc;
            } else if (CoursesCurriculum__Reaction.reactionValue(reaction) === reactionValue && CoursesCurriculum__Reaction.userId(reaction) === CoursesCurriculum__CurrentUser.id(currentUser)) {
              return CoursesCurriculum__Reaction.id(reaction);
            } else {
              return ;
            }
          }));
    if (reactionId !== undefined) {
      Js_promise2.then(Curry._3($$fetch, undefined, undefined, {
                reactionId: reactionId
              }), (function (response) {
              if (response.removeReaction.success) {
                Curry._1(setReactions, (function (reactions) {
                        return reactions.filter(function (reaction) {
                                    return CoursesCurriculum__Reaction.id(reaction) !== reactionId;
                                  });
                      }));
              }
              return Promise.resolve(undefined);
            }));
      return ;
    }
    
  };
  var addReactionCB = function (reactionValue, $$event) {
    $$event.preventDefault();
    handleCreateReaction(reactionValue);
  };
  var handleAddNewEmoji = function (e) {
    handleCreateReaction(e.native);
  };
  var aggregatedReactions = groupByReaction(reactions$1);
  return React.createElement("div", {
              className: "flex md:flex-row flex-wrap gap-1.5"
            }, Belt_Array.map(Belt_MapString.toArray(aggregatedReactions), (function (param) {
                    var reactionValue = param[0];
                    return React.createElement(CoursesCurriculum__ReactionButton.make, {
                                currentUser: currentUser,
                                reactionValue: reactionValue,
                                reactionDetails: param[1],
                                addReactionCB: addReactionCB,
                                removeReactionCB: removeReactionCB,
                                key: reactionValue
                              });
                  })), React.createElement(EmojiPicker.make, {
                  className: "relative z-[9] px-1 md:px-2 py-0.5 md:pt-1 md:pb-0.5 flex items-center justify-center bg-white border border-gray-300 rounded-full text-gray-600 hover:text-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-500 transition text-base md:text-lg",
                  title: tr(undefined, undefined, "emoji_picker"),
                  onChange: handleAddNewEmoji
                }));
}

var make$1 = CoursesCurriculum__Reactions;

export {
  str ,
  tr ,
  CreateReactionMutation ,
  RemoveReactionMutation ,
  groupByReaction ,
  make$1 as make,
}
/* include Not a pure module */
