// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as PfIcon from "../../../packages/pf-icon/src/PfIcon.bs.js";
import * as HelpIcon from "../../../shared/components/HelpIcon.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as CoursesReport__Coach from "../types/CoursesReport__Coach.bs.js";
import * as CoursesReport__StudentOverview from "../types/CoursesReport__StudentOverview.bs.js";
import * as CoursesStudents__PersonalCoaches from "../../students/components/CoursesStudents__PersonalCoaches.bs.js";
import * as CoursesReport__EvaluationCriterion from "../types/CoursesReport__EvaluationCriterion.bs.js";
import * as CoursesReport__MilestoneCompletionStatus from "../types/CoursesReport__MilestoneCompletionStatus.bs.js";

import "./CoursesReport__Overview.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesReport__Overview";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

function avatar(avatarUrl, name) {
  var avatarClasses = "w-8 h-8 md:w-10 md:h-10 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover";
  if (avatarUrl !== undefined) {
    return React.createElement("img", {
                className: avatarClasses,
                src: avatarUrl
              });
  } else {
    return React.createElement(Avatar.make, {
                name: name,
                className: avatarClasses
              });
  }
}

function userInfo(key, avatarUrl, name, title) {
  return React.createElement("div", {
              key: key,
              className: "w-full md:w-1/2 shadow rounded-lg p-4 flex items-center mt-2 bg-white"
            }, CoursesStudents__PersonalCoaches.avatar(undefined, avatarUrl, name), React.createElement("div", {
                  className: "ms-2 md:ms-3"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, name), React.createElement("div", {
                      className: "text-xs"
                    }, title)));
}

function coachInfo(coaches) {
  if (ArrayUtils.isNotEmpty(coaches)) {
    return React.createElement("div", {
                className: "mt-8"
              }, React.createElement("p", {
                    className: "text-sm font-semibold"
                  }, t(undefined, undefined, "personal_coaches")), $$Array.mapi((function (index, coach) {
                      return userInfo(String(index), CoursesReport__Coach.avatarUrl(coach), CoursesReport__Coach.name(coach), CoursesReport__Coach.title(coach));
                    }), coaches));
  } else {
    return null;
  }
}

function doughnutChart(color, percentage) {
  return React.createElement("svg", {
              className: "courses-report-overview__doughnut-chart " + color,
              viewBox: "0 0 36 36"
            }, React.createElement("path", {
                  className: "courses-report-overview__doughnut-chart-bg",
                  d: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                }), React.createElement("path", {
                  className: "courses-report-overview__doughnut-chart-stroke",
                  d: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831",
                  strokeDasharray: percentage + ", 100"
                }), React.createElement("text", {
                  className: "courses-report-overview__doughnut-chart-text font-semibold",
                  x: "50%",
                  y: "58%"
                }, percentage + "%"));
}

function assignmentsCompletionStatus(overview) {
  var assignmentsCompleted = CoursesReport__StudentOverview.assignmentsCompleted(overview);
  var totalAssignments = CoursesReport__StudentOverview.totalAssignments(overview);
  var assignmentsPendingReview = CoursesReport__StudentOverview.assignmentsPendingReview(overview);
  var incompleteAssignments = ((totalAssignments | 0) - (assignmentsCompleted | 0) | 0) - assignmentsPendingReview | 0;
  var assignmentsCompletionPercent = String(assignmentsCompleted / totalAssignments * 100.0 | 0);
  return React.createElement("div", {
              "aria-label": "assignments-completion-status",
              className: "w-full lg:w-1/2 px-2"
            }, React.createElement("div", {
                  className: "courses-report-overview__doughnut-chart-container bg-white flex-col gap-1"
                }, React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement("div", undefined, doughnutChart("purple", assignmentsCompletionPercent)), React.createElement("div", {
                          className: "ms-4"
                        }, React.createElement("p", {
                              className: "text-sm text-gray-600 font-semibold mt-1"
                            }, t([[
                                    "count",
                                    String(incompleteAssignments)
                                  ]], undefined, "incomplete_assignments")), React.createElement("p", {
                              className: "text-sm text-gray-600 font-semibold mt-1"
                            }, t([[
                                    "targetsCount",
                                    String(assignmentsPendingReview)
                                  ]], undefined, "targets_pending_review")), React.createElement("p", {
                              className: "text-sm text-gray-600 font-semibold mt-1"
                            }, t([[
                                    "targetsCount",
                                    String(assignmentsCompleted | 0)
                                  ]], undefined, "targets_completed")))), React.createElement("div", {
                      className: "text-sm font-semibold text-center"
                    }, ts(undefined, undefined, "assignments"))));
}

function pagesReadStatus(overview) {
  var totalPageReads = CoursesReport__StudentOverview.totalPageReads(overview);
  var totalTargets = CoursesReport__StudentOverview.totalTargets(overview);
  var pagesReadPercent = String(totalPageReads / totalTargets * 100.0 | 0);
  var pendingTargets = (totalTargets | 0) - (totalPageReads | 0) | 0;
  return React.createElement("div", {
              "aria-label": "targets-read-status",
              className: "w-full lg:w-1/2 px-2 mt-2 lg:mt-0 "
            }, React.createElement("div", {
                  className: "courses-report-overview__doughnut-chart-container bg-white flex-col gap-1"
                }, React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement("div", undefined, doughnutChart("purple", pagesReadPercent)), React.createElement("div", {
                          className: "ms-4"
                        }, React.createElement("p", {
                              className: "text-sm text-gray-600 font-semibold mt-1"
                            }, t([[
                                    "pagesUnread",
                                    String(pendingTargets)
                                  ]], undefined, "pages_unread")), React.createElement("p", {
                              className: "text-sm text-gray-600 font-semibold mt-1"
                            }, t([[
                                    "pagesRead",
                                    String(totalPageReads | 0)
                                  ]], undefined, "pages_read")))), React.createElement("div", {
                      className: "text-sm font-semibold text-center"
                    }, ts(undefined, undefined, "targets"))));
}

function quizPerformanceChart(averageQuizScore, quizzesAttempted) {
  if (averageQuizScore !== undefined) {
    return React.createElement("div", {
                "aria-label": "quiz-performance-chart",
                className: "w-full lg:w-1/2 px-2 mt-2"
              }, React.createElement("div", {
                    className: "courses-report-overview__doughnut-chart-container bg-white"
                  }, React.createElement("div", undefined, doughnutChart("pink", String(averageQuizScore | 0))), React.createElement("div", {
                        className: "ms-4"
                      }, React.createElement("p", {
                            className: "text-sm font-semibold mt-3"
                          }, t(undefined, undefined, "average_quiz_score")), React.createElement("p", {
                            className: "text-sm text-gray-600 font-semibold leading-tight mt-1"
                          }, t(undefined, quizzesAttempted, "quizzes_attempted")))));
  } else {
    return null;
  }
}

function milestonesCompletionStatus(overview) {
  var milestones = CoursesReport__StudentOverview.milestonesCompletionStatus(overview);
  var totalMilestones = milestones.length;
  var completedMilestones = milestones.filter(function (milestone) {
        return milestone.completed === true;
      }).length;
  var milestonesCompletionPercentage = String(completedMilestones / totalMilestones * 100.0 | 0);
  return React.createElement("div", {
              className: "flex items-center gap-2 flex-shrink-0"
            }, React.createElement("p", {
                  className: "text-xs font-medium text-gray-500"
                }, String(completedMilestones) + " / " + String(totalMilestones), React.createElement("span", {
                      className: "px-2 text-gray-300"
                    }, "|"), ts([[
                        "percentage",
                        milestonesCompletionPercentage
                      ]], undefined, "percentage_completed")), React.createElement("div", undefined, React.createElement("svg", {
                      className: "courses-milestone-complete__doughnut-chart ",
                      viewBox: "0 0 36 36"
                    }, React.createElement("path", {
                          className: "courses-milestone-complete__doughnut-chart-bg ",
                          d: "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831"
                        }), React.createElement("path", {
                          className: "courses-milestone-complete__doughnut-chart-stroke",
                          d: "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831",
                          strokeDasharray: milestonesCompletionPercentage + ", 100"
                        }))));
}

function averageGradeCharts(evaluationCriteria, averageGrades) {
  return $$Array.map((function (grade) {
                var criterion = CoursesReport__StudentOverview.evaluationCriterionForGrade(grade, evaluationCriteria);
                return React.createElement("div", {
                            key: CoursesReport__EvaluationCriterion.id(criterion),
                            "aria-label": "average-grade-for-criterion-" + CoursesReport__EvaluationCriterion.id(criterion),
                            className: "flex w-full lg:w-1/3 px-2 mt-2"
                          }, React.createElement("div", {
                                className: "courses-report-overview__pie-chart-container"
                              }, React.createElement("div", {
                                    className: "flex px-5 pt-4 text-center items-center"
                                  }, React.createElement("svg", {
                                        className: "courses-report-overview__pie-chart courses-report-overview__pie-chart--pass",
                                        viewBox: "0 0 32 32"
                                      }, React.createElement("circle", {
                                            className: "courses-report-overview__pie-chart-circle courses-report-overview__pie-chart-circle--pass",
                                            cx: "16",
                                            cy: "16",
                                            r: "16",
                                            strokeDasharray: CoursesReport__StudentOverview.gradeAsPercentage(grade, criterion) + ", 100"
                                          })), React.createElement("span", {
                                        className: "ms-3 text-lg font-semibold"
                                      }, grade.grade.toString() + ("/" + String(criterion.maxGrade)))), React.createElement("p", {
                                    className: "text-sm font-semibold px-5 pt-3 pb-4"
                                  }, CoursesReport__EvaluationCriterion.name(criterion))));
              }), averageGrades);
}

function CoursesReport__Overview(Props) {
  var overviewData = Props.overviewData;
  var coaches = Props.coaches;
  var tmp;
  if (overviewData) {
    var overview = overviewData._0;
    tmp = React.createElement("div", {
          className: "flex flex-col"
        }, React.createElement("div", {
              className: "w-full"
            }, React.createElement("div", {
                  className: "mt-8"
                }, React.createElement("h6", {
                      className: "text-sm font-semibold"
                    }, ts(undefined, undefined, "cohort")), React.createElement("div", {
                      className: "max-w-auto shadow rounded-lg p-4 items-center mt-2 bg-white flex shrink gap-2"
                    }, React.createElement("div", undefined, React.createElement(PfIcon.make, {
                              className: "if i-users-light font-normal text-lg"
                            })), React.createElement("p", {
                          className: "text-sm font-semibold break-all"
                        }, CoursesReport__StudentOverview.cohortName(overview)))), React.createElement("div", {
                  className: "mt-8"
                }, React.createElement("p", {
                      className: "text-sm font-semibold"
                    }, ts(undefined, undefined, "targets_overview")), React.createElement("div", {
                      className: "flex -mx-2 flex-wrap mt-2"
                    }, assignmentsCompletionStatus(overview), pagesReadStatus(overview), quizPerformanceChart(CoursesReport__StudentOverview.averageQuizScore(overview), CoursesReport__StudentOverview.quizzesAttempted(overview)))), ArrayUtils.isNotEmpty(CoursesReport__StudentOverview.averageGrades(overview)) ? React.createElement("div", {
                    className: "mt-8"
                  }, React.createElement("h6", {
                        className: "text-sm font-semibold"
                      }, t(undefined, undefined, "average_grades")), React.createElement("div", {
                        className: "flex -mx-2 flex-wrap"
                      }, averageGradeCharts(CoursesReport__StudentOverview.evaluationCriteria(overview), CoursesReport__StudentOverview.averageGrades(overview)))) : null, coachInfo(coaches), React.createElement("div", undefined, React.createElement("div", {
                      className: "flex justify-between mt-8"
                    }, React.createElement("p", {
                          className: "text-sm font-semibold"
                        }, React.createElement("span", undefined, ts(undefined, undefined, "milestones")), React.createElement(HelpIcon.make, {
                              className: "ml-2",
                              responsiveAlignment: {
                                TAG: /* Responsive */1,
                                _0: /* AlignLeft */0,
                                _1: /* AlignCenter */2
                              },
                              children: t(undefined, undefined, "milestone_status_help")
                            })), milestonesCompletionStatus(overview)), React.createElement("div", {
                      className: "grid gap-2 mt-2"
                    }, ArrayUtils.copyAndSort((function (a, b) {
                              return CoursesReport__MilestoneCompletionStatus.milestoneNumber(a) - CoursesReport__MilestoneCompletionStatus.milestoneNumber(b) | 0;
                            }), CoursesReport__StudentOverview.milestonesCompletionStatus(overview)).map(function (data) {
                          return React.createElement("a", {
                                      className: "flex gap-2 items-center justify-between p-2 rounded-md border bg-gray-100 hover:bg-primary-100 hover:border-primary-500 hover:text-primary-500 transition",
                                      href: "/targets/" + CoursesReport__MilestoneCompletionStatus.id(data)
                                    }, React.createElement("div", {
                                          className: "flex items-center gap-2"
                                        }, React.createElement("p", {
                                              className: "text-sm font-semibold"
                                            }, ts(undefined, undefined, "m") + String(CoursesReport__MilestoneCompletionStatus.milestoneNumber(data))), React.createElement("p", {
                                              className: "max-w-[16ch] sm:max-w-[40ch] md:max-w-[32ch] lg:max-w-[56ch] 2xl:max-w-[64ch] truncate text-sm"
                                            }, CoursesReport__MilestoneCompletionStatus.title(data))), React.createElement("div", {
                                          className: "flex-shrink-0"
                                        }, React.createElement("span", {
                                              className: "text-xs font-medium inline-flex items-center " + (
                                                CoursesReport__MilestoneCompletionStatus.completed(data) ? "text-green-700 bg-green-100 px-1 py-0.5 rounded" : "text-orange-700 bg-orange-100 px-1 py-0.5 rounded"
                                              )
                                            }, React.createElement(Icon.make, {
                                                  className: CoursesReport__MilestoneCompletionStatus.completed(data) ? "if i-check-circle-solid text-green-600" : "if i-dashed-circle-light text-orange-600"
                                                }), CoursesReport__MilestoneCompletionStatus.completed(data) ? React.createElement("span", {
                                                    className: "ms-1"
                                                  }, t(undefined, undefined, "milestone_completed")) : React.createElement("span", {
                                                    className: "ms-1"
                                                  }, t(undefined, undefined, "milestone_pending")))));
                        })))));
  } else {
    tmp = React.createElement("div", {
          className: "flex flex-col"
        }, React.createElement("div", {
              className: "w-full bg-white p-8"
            }, SkeletonLoading.heading(undefined), SkeletonLoading.multiple(2, SkeletonLoading.paragraph(undefined)), SkeletonLoading.multiple(2, SkeletonLoading.userDetails(undefined))));
  }
  return React.createElement("div", {
              className: "max-w-3xl mx-auto"
            }, tmp);
}

var make = CoursesReport__Overview;

export {
  str ,
  t ,
  ts ,
  avatar ,
  userInfo ,
  coachInfo ,
  doughnutChart ,
  assignmentsCompletionStatus ,
  pagesReadStatus ,
  quizPerformanceChart ,
  milestonesCompletionStatus ,
  averageGradeCharts ,
  make ,
}
/*  Not a pure module */
