// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_array from "../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Caml_format from "../../../../../node_modules/rescript/lib/es6/caml_format.js";
import * as CoursesStudents__CoachNote from "./CoursesStudents__CoachNote.bs.js";
import * as CoursesStudents__EvaluationCriterion from "./CoursesStudents__EvaluationCriterion.bs.js";

function student(t) {
  return t.student;
}

function team(t) {
  return t.team;
}

function students(team) {
  return team.students;
}

function coachNotes(t) {
  return t.coachNotes;
}

function hasArchivedNotes(t) {
  return t.hasArchivedNotes;
}

function canModifyCoachNotes(t) {
  return t.canModifyCoachNotes;
}

function courseId(t) {
  return t.courseId;
}

function makeAverageGrade(evaluationCriterionId, grade) {
  return {
          evaluationCriterionId: evaluationCriterionId,
          grade: grade
        };
}

function totalPageReads(t) {
  return t.totalPageReads;
}

function totalTargets(t) {
  return t.totalTargets;
}

function gradeAsPercentage(averageGrade, evaluationCriterion) {
  var maxGrade = evaluationCriterion.maxGrade;
  return String(averageGrade.grade / maxGrade * 100.0 | 0);
}

function assignmentsCompleted(t) {
  return t.assignmentsCompleted;
}

function totalAssignments(t) {
  return t.totalAssignments;
}

function quizzesAttempted(t) {
  return t.quizScores.length;
}

function evaluationCriteria(t) {
  return t.evaluationCriteria;
}

function averageGrades(t) {
  return t.averageGrades;
}

function gradeValue(averageGrade) {
  return averageGrade.grade;
}

function milestonesCompletionStatus(t) {
  return t.milestonesCompletionStatus;
}

function evaluationCriterionForGrade(grade, evaluationCriteria, componentName) {
  return ArrayUtils.unsafeFind((function (ec) {
                return CoursesStudents__EvaluationCriterion.id(ec) === grade.evaluationCriterionId;
              }), "Unable to find evaluation criterion with id: " + (grade.evaluationCriterionId + (" in component: " + componentName)), evaluationCriteria);
}

function addNewNote(note, t) {
  var notes = $$Array.append(t.coachNotes, [note]);
  return {
          id: t.id,
          coachNotes: notes,
          hasArchivedNotes: t.hasArchivedNotes,
          canModifyCoachNotes: t.canModifyCoachNotes,
          evaluationCriteria: t.evaluationCriteria,
          totalPageReads: t.totalPageReads,
          totalTargets: t.totalTargets,
          assignmentsCompleted: t.assignmentsCompleted,
          totalAssignments: t.totalAssignments,
          quizScores: t.quizScores,
          averageGrades: t.averageGrades,
          student: t.student,
          team: t.team,
          milestonesCompletionStatus: t.milestonesCompletionStatus,
          courseId: t.courseId
        };
}

function removeNote(noteId, t) {
  var notes = Js_array.filter((function (note) {
          return CoursesStudents__CoachNote.id(note) !== noteId;
        }), t.coachNotes);
  return {
          id: t.id,
          coachNotes: notes,
          hasArchivedNotes: true,
          canModifyCoachNotes: t.canModifyCoachNotes,
          evaluationCriteria: t.evaluationCriteria,
          totalPageReads: t.totalPageReads,
          totalTargets: t.totalTargets,
          assignmentsCompleted: t.assignmentsCompleted,
          totalAssignments: t.totalAssignments,
          quizScores: t.quizScores,
          averageGrades: t.averageGrades,
          student: t.student,
          team: t.team,
          milestonesCompletionStatus: t.milestonesCompletionStatus,
          courseId: t.courseId
        };
}

function computeAverageQuizScore(quizScores) {
  var sumOfPercentageScores = Js_array.reduce((function (a, b) {
          return a + b;
        }), 0.0, $$Array.map((function (quizScore) {
              var fractionArray = $$Array.of_list($$String.split_on_char(/* '/' */47, quizScore));
              var numerator = Caml_format.float_of_string(Caml_array.get(fractionArray, 0));
              var denominator = Caml_format.float_of_string(Caml_array.get(fractionArray, 1));
              return numerator / denominator * 100.0;
            }), quizScores));
  return sumOfPercentageScores / quizScores.length;
}

function averageQuizScore(t) {
  if (ArrayUtils.isEmpty(t.quizScores)) {
    return ;
  } else {
    return computeAverageQuizScore(t.quizScores);
  }
}

function makeTeam(id, name, students) {
  return {
          id: id,
          name: name,
          students: students
        };
}

function make(id, coachNotes, hasArchivedNotes, canModifyCoachNotes, evaluationCriteria, totalPageReads, totalTargets, assignmentsCompleted, totalAssignments, quizScores, averageGrades, student, team, courseId, milestonesCompletionStatus) {
  return {
          id: id,
          coachNotes: coachNotes,
          hasArchivedNotes: hasArchivedNotes,
          canModifyCoachNotes: canModifyCoachNotes,
          evaluationCriteria: evaluationCriteria,
          totalPageReads: totalPageReads,
          totalTargets: totalTargets,
          assignmentsCompleted: assignmentsCompleted,
          totalAssignments: totalAssignments,
          quizScores: quizScores,
          averageGrades: averageGrades,
          student: student,
          team: team,
          milestonesCompletionStatus: milestonesCompletionStatus,
          courseId: courseId
        };
}

export {
  student ,
  team ,
  students ,
  coachNotes ,
  hasArchivedNotes ,
  canModifyCoachNotes ,
  courseId ,
  makeAverageGrade ,
  totalPageReads ,
  totalTargets ,
  gradeAsPercentage ,
  assignmentsCompleted ,
  totalAssignments ,
  quizzesAttempted ,
  evaluationCriteria ,
  averageGrades ,
  gradeValue ,
  milestonesCompletionStatus ,
  evaluationCriterionForGrade ,
  addNewNote ,
  removeNote ,
  computeAverageQuizScore ,
  averageQuizScore ,
  makeTeam ,
  make ,
}
/* ArrayUtils Not a pure module */
