// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_array from "../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Caml_format from "../../../../../node_modules/rescript/lib/es6/caml_format.js";
import * as CoursesReport__EvaluationCriterion from "./CoursesReport__EvaluationCriterion.bs.js";

function id(t) {
  return t.id;
}

function cohortName(t) {
  return t.cohortName;
}

function evaluationCriteria(t) {
  return t.evaluationCriteria;
}

function totalPageReads(t) {
  return t.totalPageReads;
}

function totalTargets(t) {
  return t.totalTargets;
}

function assignmentsPendingReview(t) {
  return t.assignmentsPendingReview;
}

function assignmentsCompleted(t) {
  return t.assignmentsCompleted;
}

function totalAssignments(t) {
  return t.totalAssignments;
}

function quizzesAttempted(t) {
  return t.quizScores.length;
}

function quizScores(t) {
  return t.quizScores;
}

function averageGrades(t) {
  return t.averageGrades;
}

function makeAverageGrade(evaluationCriterionId, grade) {
  return {
          evaluationCriterionId: evaluationCriterionId,
          grade: grade
        };
}

function milestonesCompletionStatus(t) {
  return t.milestonesCompletionStatus;
}

function evaluationCriterionForGrade(grade, evaluationCriteria) {
  return ArrayUtils.unsafeFind((function (ec) {
                return CoursesReport__EvaluationCriterion.id(ec) === grade.evaluationCriterionId;
              }), "Unable to find evaluation criterion with id: " + (grade.evaluationCriterionId + " in component: CoursesReport__Overview"), evaluationCriteria);
}

function gradeValue(averageGrade) {
  return averageGrade.grade;
}

function gradeAsPercentage(averageGrade, evaluationCriterion) {
  var maxGrade = evaluationCriterion.maxGrade;
  return String(averageGrade.grade / maxGrade * 100.0 | 0);
}

function computeAverageQuizScore(quizScores) {
  var sumOfPercentageScores = quizScores.map(function (quizScore) {
          var fractionArray = $$Array.of_list($$String.split_on_char(/* '/' */47, quizScore));
          var numerator = Caml_format.float_of_string(Caml_array.get(fractionArray, 0));
          var denominator = Caml_format.float_of_string(Caml_array.get(fractionArray, 1));
          return numerator / denominator * 100.0;
        }).reduce((function (a, b) {
          return a + b;
        }), 0.0);
  return sumOfPercentageScores / quizScores.length;
}

function averageQuizScore(t) {
  if (ArrayUtils.isEmpty(t.quizScores)) {
    return ;
  } else {
    return computeAverageQuizScore(t.quizScores);
  }
}

function make(id, cohortName, evaluationCriteria, totalPageReads, totalTargets, assignmentsCompleted, totalAssignments, quizScores, averageGrades, assignmentsPendingReview, milestonesCompletionStatus) {
  return {
          id: id,
          cohortName: cohortName,
          evaluationCriteria: evaluationCriteria,
          totalPageReads: totalPageReads,
          totalTargets: totalTargets,
          assignmentsPendingReview: assignmentsPendingReview,
          assignmentsCompleted: assignmentsCompleted,
          totalAssignments: totalAssignments,
          quizScores: quizScores,
          averageGrades: averageGrades,
          milestonesCompletionStatus: milestonesCompletionStatus
        };
}

export {
  id ,
  cohortName ,
  evaluationCriteria ,
  totalPageReads ,
  totalTargets ,
  assignmentsPendingReview ,
  assignmentsCompleted ,
  totalAssignments ,
  quizzesAttempted ,
  quizScores ,
  averageGrades ,
  makeAverageGrade ,
  milestonesCompletionStatus ,
  evaluationCriterionForGrade ,
  gradeValue ,
  gradeAsPercentage ,
  computeAverageQuizScore ,
  averageQuizScore ,
  make ,
}
/* ArrayUtils Not a pure module */
