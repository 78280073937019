// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as CoursesCurriculum__User from "../types/CoursesCurriculum__User.bs.js";
import * as SubmissionChecklistShow from "../../review/components/SubmissionChecklistShow.bs.js";
import * as CoursesCurriculum__Reactions from "./CoursesCurriculum__Reactions.bs.js";
import * as CoursesCurriculum__CurrentUser from "../types/CoursesCurriculum__CurrentUser.bs.js";
import * as CoursesCurriculum__SubmissionComments from "./CoursesCurriculum__SubmissionComments.bs.js";
import * as CoursesCurriculum__DiscussionSubmission from "../types/CoursesCurriculum__DiscussionSubmission.bs.js";
import * as CoursesCurriculum__ModerationReportButton from "./CoursesCurriculum__ModerationReportButton.bs.js";

import "./CoursesCurriculum__DiscussSubmission.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__DiscussSubmission";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation PinSubmissionMutation($pin: Boolean!, $submissionId: String!)  {\npinSubmission(pin: $pin, submissionId: $submissionId)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.pinSubmission;
  return {
          pinSubmission: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.pinSubmission;
  var value$2 = value$1.success;
  var pinSubmission = {
    success: value$2
  };
  return {
          pinSubmission: pinSubmission
        };
}

function serializeVariables(inp) {
  return {
          pin: inp.pin,
          submissionId: inp.submissionId
        };
}

function makeVariables(pin, submissionId, param) {
  return {
          pin: pin,
          submissionId: submissionId
        };
}

var PinSubmissionMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var PinSubmissionMutation_Graphql_error = include.Graphql_error;

var PinSubmissionMutation_decodeNotification = include.decodeNotification;

var PinSubmissionMutation_decodeObject = include.decodeObject;

var PinSubmissionMutation_decodeNotifications = include.decodeNotifications;

var PinSubmissionMutation_decodeErrors = include.decodeErrors;

var PinSubmissionMutation_flashNotifications = include.flashNotifications;

var PinSubmissionMutation_sendQuery = include.sendQuery;

var PinSubmissionMutation_query = include.query;

var PinSubmissionMutation_fetch = include.$$fetch;

var PinSubmissionMutation = {
  PinSubmissionMutation_inner: PinSubmissionMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: PinSubmissionMutation_Graphql_error,
  decodeNotification: PinSubmissionMutation_decodeNotification,
  decodeObject: PinSubmissionMutation_decodeObject,
  decodeNotifications: PinSubmissionMutation_decodeNotifications,
  decodeErrors: PinSubmissionMutation_decodeErrors,
  flashNotifications: PinSubmissionMutation_flashNotifications,
  sendQuery: PinSubmissionMutation_sendQuery,
  query: PinSubmissionMutation_query,
  $$fetch: PinSubmissionMutation_fetch,
  make: make
};

var Raw$1 = {};

var query$1 = "mutation HideSubmissionMutation($submissionId: String!, $hide: Boolean!)  {\nhideSubmission(submissionId: $submissionId, hide: $hide)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.hideSubmission;
  return {
          hideSubmission: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.hideSubmission;
  var value$2 = value$1.success;
  var hideSubmission = {
    success: value$2
  };
  return {
          hideSubmission: hideSubmission
        };
}

function serializeVariables$1(inp) {
  return {
          submissionId: inp.submissionId,
          hide: inp.hide
        };
}

function makeVariables$1(submissionId, hide, param) {
  return {
          submissionId: submissionId,
          hide: hide
        };
}

var HideSubmissionMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$1 = include$1.make;

var HideSubmissionMutation_Graphql_error = include$1.Graphql_error;

var HideSubmissionMutation_decodeNotification = include$1.decodeNotification;

var HideSubmissionMutation_decodeObject = include$1.decodeObject;

var HideSubmissionMutation_decodeNotifications = include$1.decodeNotifications;

var HideSubmissionMutation_decodeErrors = include$1.decodeErrors;

var HideSubmissionMutation_flashNotifications = include$1.flashNotifications;

var HideSubmissionMutation_sendQuery = include$1.sendQuery;

var HideSubmissionMutation_query = include$1.query;

var HideSubmissionMutation_fetch = include$1.$$fetch;

var HideSubmissionMutation = {
  HideSubmissionMutation_inner: HideSubmissionMutation_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: HideSubmissionMutation_Graphql_error,
  decodeNotification: HideSubmissionMutation_decodeNotification,
  decodeObject: HideSubmissionMutation_decodeObject,
  decodeNotifications: HideSubmissionMutation_decodeNotifications,
  decodeErrors: HideSubmissionMutation_decodeErrors,
  flashNotifications: HideSubmissionMutation_flashNotifications,
  sendQuery: HideSubmissionMutation_sendQuery,
  query: HideSubmissionMutation_query,
  $$fetch: HideSubmissionMutation_fetch,
  make: make$1
};

function pinSubmission(submission, callback, $$event) {
  $$event.preventDefault();
  var pin = !CoursesCurriculum__DiscussionSubmission.pinned(submission);
  var submissionId = CoursesCurriculum__DiscussionSubmission.id(submission);
  Js_promise.then_((function (response) {
          if (response.pinSubmission.success) {
            Curry._1(callback, CoursesCurriculum__DiscussionSubmission.targetId(submission));
          }
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, {
            pin: pin,
            submissionId: submissionId
          }));
}

function hideSubmission(submission, hide, setSubmissionHidden, $$event) {
  $$event.preventDefault();
  var submissionId = CoursesCurriculum__DiscussionSubmission.id(submission);
  Js_promise.then_((function (response) {
          if (response.hideSubmission.success) {
            Curry._1(setSubmissionHidden, (function (param) {
                    return hide;
                  }));
          }
          return Promise.resolve(undefined);
        }), Curry._3(make$1, undefined, undefined, {
            submissionId: submissionId,
            hide: hide
          }));
}

function pinnedClasses(pinned) {
  if (pinned) {
    return "bg-white px-4 md:px-6 pt-6 pb-2 rounded-lg shadow-xl dark:shadow-2xl border border-gray-200/75 dark:border-gray-200";
  } else {
    return "py-4";
  }
}

function CoursesCurriculum__DiscussSubmission(Props) {
  var currentUser = Props.currentUser;
  var submission = Props.submission;
  var callBack = Props.callBack;
  var submissionId = CoursesCurriculum__DiscussionSubmission.id(submission);
  var match = React.useState(function () {
        return Belt_Option.isSome(CoursesCurriculum__DiscussionSubmission.hiddenAt(submission));
      });
  var setSubmissionHidden = match[1];
  var submissionHidden = match[0];
  var teamStrength = CoursesCurriculum__DiscussionSubmission.users(submission).length;
  var pinned = CoursesCurriculum__DiscussionSubmission.pinned(submission);
  var tmp;
  if (CoursesCurriculum__DiscussionSubmission.anonymous(submission)) {
    tmp = React.createElement("span", {
          className: "font-semibold text-xs leading-tight block md:inline-flex"
        }, t(undefined, undefined, "anonymous_name"));
  } else {
    var name = CoursesCurriculum__DiscussionSubmission.teamName(submission);
    tmp = name !== undefined ? React.createElement("span", {
            className: "font-semibold text-xs leading-tight block md:inline-flex"
          }, React.createElement("span", {
                className: "text-gray-500"
              }, t(undefined, undefined, "submitted_by_team")), React.createElement("span", {
                className: "ms-1"
              }, name)) : React.createElement("span", {
            className: "font-semibold text-xs leading-tight block md:inline-flex"
          }, CoursesCurriculum__DiscussionSubmission.userNames(submission));
  }
  var tmp$1;
  if (CoursesCurriculum__CurrentUser.isModerator(currentUser)) {
    var partial_arg = !submissionHidden;
    tmp$1 = React.createElement("div", {
          className: "flex space-x-2 relative z-[12]"
        }, React.createElement("button", {
              className: "curriculum-discuss-submission__pin-button md:hidden md:group-hover:flex md:group-focus-within:flex items-center justify-center cursor-pointer p-1 text-sm border rounded-md text-gray-700 bg-gray-100 hover:text-gray-800 hover:bg-gray-50 whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-600 transition",
              onClick: (function (param) {
                  return pinSubmission(submission, callBack, param);
                })
            }, CoursesCurriculum__DiscussionSubmission.pinned(submission) ? React.createElement("span", {
                    className: "flex items-center md:space-x-1"
                  }, React.createElement(Icon.make, {
                        className: "if i-pin-angle-light if-fw"
                      }), React.createElement("span", {
                        className: "hidden md:inline-block text-xs"
                      }, t(undefined, undefined, "unpin"))) : React.createElement("span", {
                    className: "flex items-center md:space-x-1"
                  }, React.createElement(Icon.make, {
                        className: "if i-pin-angle-light if-fw"
                      }), React.createElement("span", {
                        className: "hidden md:inline-block text-xs"
                      }, t(undefined, undefined, "pin")))), React.createElement("button", {
              className: "curriculum-discuss-submission__hide-button md:hidden md:group-hover:flex md:group-focus-within:flex items-center justify-center cursor-pointer p-1 text-sm border rounded-md text-gray-700 bg-gray-100 hover:text-gray-800 hover:bg-gray-50 whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-600 transition",
              onClick: (function (param) {
                  return hideSubmission(submission, partial_arg, setSubmissionHidden, param);
                })
            }, submissionHidden ? React.createElement("span", {
                    className: "flex items-center md:space-x-1"
                  }, React.createElement(Icon.make, {
                        className: "if i-eye-closed-light if-fw"
                      }), React.createElement("span", {
                        className: "hidden md:inline-block text-xs"
                      }, t(undefined, undefined, "unhide"))) : React.createElement("span", {
                    className: "flex items-center md:space-x-1"
                  }, React.createElement(Icon.make, {
                        className: "if i-eye-light if-fw"
                      }), React.createElement("span", {
                        className: "hidden md:inline-block text-xs"
                      }, t(undefined, undefined, "hide")))));
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              "aria-label": "discuss_submission-" + submissionId,
              className: "relative curriculum-discuss-submission__container focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-500 " + (
                pinned ? "bg-white px-4 md:px-6 pt-6 pb-2 rounded-lg shadow-xl dark:shadow-2xl border border-gray-200/75 dark:border-gray-200" : "py-4"
              ) + (
                submissionHidden ? " curriculum-discuss-submission__hidden max-h-48 overflow-y-hidden" : ""
              ),
              id: "discuss_submission-" + submissionId,
              tabIndex: 0
            }, CoursesCurriculum__DiscussionSubmission.pinned(submission) ? React.createElement("p", {
                    className: "absolute bg-green-100 inline-flex items-center text-green-800 text-xs border border-green-300 px-1.5 py-0.5 leading-tight rounded-md -top-3 " + (
                      submissionHidden ? " hidden" : ""
                    )
                  }, React.createElement(Icon.make, {
                        className: "if i-pin-angle-light if-fw"
                      }), React.createElement("span", {
                        className: "ps-1.5"
                      }, t(undefined, undefined, "pinned_submission"))) : null, React.createElement("div", {
                  className: "flex items-start justify-between"
                }, React.createElement("div", {
                      className: "flex gap-3"
                    }, React.createElement("div", {
                          className: "isolate flex -space-x-2 overflow-hidden"
                        }, React.createElement("div", {
                              className: "w-8 h-8 relative z-10 uppercase text-xs font-semibold border bg-gray-200 rounded-full flex items-center justify-center"
                            }, CoursesCurriculum__DiscussionSubmission.anonymous(submission) ? React.createElement("span", {
                                    className: "font-semibold"
                                  }, t(undefined, undefined, "anonymous_avatar")) : CoursesCurriculum__User.avatar(CoursesCurriculum__DiscussionSubmission.firstUser(submission)), React.createElement("span", {
                                  className: "font-semibold"
                                })), teamStrength !== 1 ? React.createElement("div", {
                                className: "flex items-center justify-center w-8 h-8 text-xs border bg-gray-200 text-gray-600 relative z-0 rounded-full"
                              }, React.createElement("span", undefined, "+" + String(teamStrength - 1 | 0))) : null), React.createElement("div", {
                          className: "flex flex-col flex-wrap"
                        }, tmp, React.createElement("span", {
                              className: "text-xs text-gray-600 leading-tight pt-1",
                              title: CoursesCurriculum__DiscussionSubmission.createdAtPretty(submission)
                            }, t([[
                                    "created_at",
                                    CoursesCurriculum__DiscussionSubmission.createdAtPretty(submission)
                                  ]], undefined, "created_at")))), React.createElement("div", {
                      className: "flex space-x-2 relative"
                    }, tmp$1, React.createElement("div", {
                          className: "relative z-[13]"
                        }, React.createElement(CoursesCurriculum__ModerationReportButton.make, {
                              currentUser: currentUser,
                              moderationReports: CoursesCurriculum__DiscussionSubmission.moderationReports(submission),
                              reportableId: CoursesCurriculum__DiscussionSubmission.id(submission),
                              reportableType: "TimelineEvent"
                            })))), React.createElement("div", {
                  className: "relative"
                }, React.createElement("div", {
                      className: "absolute w-8 top-0 left-0 bottom-0 flex justify-center items-center z-0"
                    }, React.createElement("div", {
                          className: "w-px h-full bg-gradient-to-b from-gray-300 via-gray-300 "
                        })), React.createElement("div", {
                      className: "ms-11 pb-4 pt-6"
                    }, React.createElement(SubmissionChecklistShow.make, {
                          checklist: CoursesCurriculum__DiscussionSubmission.checklist(submission),
                          updateChecklistCB: undefined,
                          forDiscussion: true
                        })), React.createElement("div", {
                      className: "flex flex-col gap-4 items-start relative py-4 ps-11"
                    }, React.createElement("div", undefined, React.createElement(CoursesCurriculum__Reactions.make, {
                              currentUser: currentUser,
                              reactionableType: "TimelineEvent",
                              reactionableId: submissionId,
                              reactions: CoursesCurriculum__DiscussionSubmission.reactions(submission)
                            })), React.createElement("div", {
                          className: "relative w-full"
                        }, React.createElement(CoursesCurriculum__SubmissionComments.make, {
                              currentUser: currentUser,
                              submissionId: submissionId,
                              comments: CoursesCurriculum__DiscussionSubmission.comments(submission),
                              commentsInitiallyVisible: false
                            })))), submissionHidden ? React.createElement("div", {
                    className: "absolute -translate-x-1/2 left-1/2 z-[12] flex justify-end mx-auto bottom-px"
                  }, React.createElement("p", {
                        className: "px-2 py-1 bg-white/20 border border-gray-300 border-b-0 whitespace-nowrap rounded-t-lg text-xs leading-tight italic text-gray-500"
                      }, t(undefined, undefined, "submission_hidden"))) : null);
}

var make$2 = CoursesCurriculum__DiscussSubmission;

export {
  str ,
  t ,
  PinSubmissionMutation ,
  HideSubmissionMutation ,
  pinSubmission ,
  hideSubmission ,
  pinnedClasses ,
  make$2 as make,
}
/*  Not a pure module */
